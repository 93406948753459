<template>
  <div id="page">
    <PageHeader
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <CustomLegalText type="imprint" :companyId="$route.params.id" />
        <CustomLegalText type="dataprotection" :companyId="$route.params.id" />
        <CustomLegalText type="terms" :companyId="$route.params.id" />
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import CustomLegalText from "@/components/companies/legal/CustomLegalText.vue";
export default {
  name: "Legal",

  components: {
    PageHeader,
    Subnavigation,
    CustomLegalText,
  },

  data() {
    return {
      pageTitle: "Rechtliches",
      pageDescription:
        "Impressum, Datenschutz und Teilnahmebedingungen werden generell vom System vorgegeben. Hier können Sie diese Texte jedoch für diesen Kunden individuell anpassen.",
      title: "",
      preloader: false,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;

      /* USEING REUSEABLE MIXIN METHOD */
      let response = await this.getRequest(
        "companies/" + this.$route.params.id
      ); // await data from mixin
      this.preloader = false;
      console.log(response);

      this.title = response.data.data[0].name;
    },
  },

  mixins: [apiRequest],
};
</script>
