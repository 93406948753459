<template>
  <v-card v-if="!preloader" class="my-2">
    <v-app-bar flat color="primary">
      <v-toolbar-title class="title white--text pl-0">
        {{ title }}
      </v-toolbar-title>
      <v-spacer />

      <v-btn
        v-if="data.name"
        class="mx-2"
        fab
        dark
        small
        color="red"
        @click="deleteCustomText()"
      >
        <v-icon dark> mdi-trash-can </v-icon>
      </v-btn>

      <v-btn
        v-else
        class="mx-2"
        fab
        dark
        small
        color="green"
        @click="addCustomText()"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </v-app-bar>

    <v-card-text v-if="data.name">
      <Patcher
        :itemValue="data.value"
        itemName="value"
        itemLabel="Inhalt"
        :hideLabel="false"
        :route="`${patchRoute}/${data.id}`"
        hint="Aktuell wird der Text verwendet, den Sie hier sehen. Der Standardtext wird nicht mehr angezeigt. Dieser wird erst wieder angezeigt, wenn Sie den Text löschen. Platzhalter sind NICHT verfügbar."
        inputElement="textarea-md"
        inputType="text"
        @emit="data.value = $event.value"
      ></Patcher>
    </v-card-text>

    <v-card-text v-else> Es wird die Standardvorlage verwendet. </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "CustomLegalText",

  components: {
    Patcher,
  },

  props: {
    companyId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      data: [],
      title: "",
      preloader: false,
      patchRoute: "customLegalTexts",
    };
  },

  created: function () {
    if (this.type === "imprint") {
      this.title = "Impressum";
    } else if (this.type === "dataprotection") {
      this.title = "Datenschutz";
    } else if (this.type === "terms") {
      this.title = "Teilnahmebedingungen";
    } else {
      this.title = "";
    }

    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        "customLegalTexts/" + this.$route.params.id + "/" + this.type
      );
      this.preloader = false;
      this.data = response.data.data;
    },

    async addCustomText() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll tatsächlich ein neuer Text angelegt werden? Die Standardvorlage wird dadurch überschrieben.`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.preloader = true;
        const result = await this.postRequest(
          `/customLegalTexts/${this.companyId}/${this.type}`
        );
        this.preloader = false;
        this.data = result.data.data;
      }
    },

    async deleteCustomText() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll dieser Text gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden.`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.preloader = true;
        await this.deleteRequest(`/customLegalTexts/${this.data.id}`);
        this.preloader = false;
        this.getData();
      }
    },
  },

  mixins: [apiRequest],
};
</script>
